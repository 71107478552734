import {
    faCode,
    faFlask,
    faNetworkWired,
    faPrescription,
    faPrescriptionBottle,
    faSchool,
    faShieldAlt,
    faShieldVirus,
    faUserShield
} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { Row, Col } from "reactstrap";
import { Capability } from "../../models";
import CapabilityBlock from "./capabilityBlock";

const capabilityContent: Capability[] = [
    {
        icon: faCode,
        title: "Software Development",
        details:
            "Our Business Process Management (BPM) experts optimize your organization's workflow and offer a low code platform solution."
    },
    {
        icon: faUserShield,
        title: "Cybersecurity",
        details:
            "We go beyond assessing compliance. Tiber Creek offers a Governance, Risk, and Compliance (GRC) product to make security painless."
    },
    {
        icon: faPrescriptionBottle,
        title: "Tiber Creek Rx",
        details:
            "Our Rx Division is experienced in providing quality and timely Pharmacy Benefit Management (PBM) audits for clients of all types and sizes."
    },
    {
        icon: faSchool,
        title: "Tiber Creek K-12",
        details:
            "Our Education Services Division provides network, server, storage, messaging, and security services to support your educational goals."
    }
];

interface CapabilitiesProps {
    capabilities?: Capability[];
}

const Capabilities: React.FunctionComponent<CapabilitiesProps> = ({
    capabilities
}) => {
    if (!capabilities) {
        capabilities = capabilityContent;
    }
    return (
        <Row>
            {capabilities.map(cap => (
                <Col lg={6} xl={3} key={cap.title} className="my-3">
                    <CapabilityBlock capability={cap} />
                </Col>
            ))}
        </Row>
    );
};

export default Capabilities;
