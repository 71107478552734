import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import * as React from "react";
import { Card, CardBody, CardFooter, CardText } from "reactstrap";
import { Capability } from "../../models";

interface CapabilityBlockProps {
    capability: Capability;
}

const CapabilityBlock: React.FunctionComponent<CapabilityBlockProps> = ({
    capability
}) => (
    <Card color="secondary" className="h-100">
        <CardBody className="d-flex flex-column justify-content-between align-items-start">
            <div>
                {capability.icon && (
                    <FontAwesomeIcon
                        icon={capability.icon}
                        size="3x"
                        className="mb-5 w-100 text-center"
                    />
                )}
                <h3 className="h4 mb-4">{capability.title}</h3>
            </div>

            <CardText>{capability.details}</CardText>
        </CardBody>

        {capability.path && (
            <CardFooter className="text-center">
                <Link
                    to={capability.path}
                    className="card-link text-white w-100"
                >
                    Learn More
                </Link>
            </CardFooter>
        )}
    </Card>
);

export default CapabilityBlock;
